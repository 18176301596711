import React, { useEffect, useState } from "react";
import Pagination from "../../molecules/Pagination";
import { PAGE_SIZE } from "../../../utils/constants";
import MortgageHolderTable from "../MortgageHolderTable";
import { IMortgageHolder } from "../../../types/financialOverview";
import { useDispatch } from "react-redux";
import { setSelectedParcelLId } from "../../../redux/actions";

const CityPopup = ({
  data,
  totalCount: initialTotalCount, // Total inicial
  handlePageChange,
  loadingPagination,
  actualPage,
  loadingData,
}: {
  data: IMortgageHolder[] | undefined;
  totalCount: number;
  handlePageChange: (newPage: number) => void;
  loadingPagination: boolean;
  loadingData: boolean;
  actualPage: number;
}) => {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(initialTotalCount);

  useEffect(() => {
    if (initialTotalCount > totalCount) {
      setTotalCount(initialTotalCount);
    }
  }, [initialTotalCount]);

  const handleParcelLId = (row: IMortgageHolder) => {
    dispatch(setSelectedParcelLId(row.parcel_lid));
  };

  return (
    <div>
      <MortgageHolderTable
        data={data || []}
        isLoading={loadingData}
        onRowClick={(row: IMortgageHolder) => handleParcelLId(row)}
      />
      <Pagination
        actualPage={actualPage}
        totalCount={totalCount}
        pageSize={PAGE_SIZE}
        onPageChange={handlePageChange}
        isLoading={loadingPagination}
      />
    </div>
  );
};

export default CityPopup;
