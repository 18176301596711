import React from "react";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import CustomContent from "@arcgis/core/popup/content/CustomContent";
import { createRoot, Root } from "react-dom/client";
import { getMortgageHolders } from "../services/dashboard";
import { PAGE_SIZE } from "../utils/constants";
import { Provider } from "react-redux";
import store from "../redux/store";
import Skeleton from "../components/atoms/Skeleton";
import CityPopup from "../components/features/CityPopup";

const rootMap = new Map<Element, Root>();

export const cityDonutCustomContent = new CustomContent({
  outFields: ["*"],
  creator: (feature) => {
    const cityName = feature?.graphic?.attributes?.minorciv_1;
    const container = document.createElement("div");

    if (!cityName) {
      container.innerHTML = "<div>No city data available</div>";
      return container;
    }

    let root = rootMap.get(container);
    if (!root) {
      root = createRoot(container);
      rootMap.set(container, root);
    }

    root.render(<Skeleton borderRadius={10} height={300} />);

    let total_count = 0;
    let loadingData = true;
    const fetchAndRender = async (pageNumber: number) => {
      const response = await getMortgageHolders({
        page: pageNumber,
        page_size: PAGE_SIZE,
        sort_by: "row",
        sort_order: "asc",
        params: {
          city: cityName,
          parcel_lid: "any",
        },
        include_count: pageNumber === 1,
      });
      loadingData = false;

      if (response && response.data.length > 0) {
        if (pageNumber === 1) total_count = response.total_count;
        const handlePageChange = (newPage: number) => {
          loadingData = true;
          fetchAndRender(newPage);
        };

        let currentRoot = rootMap.get(container);
        if (!currentRoot) {
          currentRoot = createRoot(container);
          rootMap.set(container, currentRoot);
        }

        currentRoot.render(
          <Provider store={store}>
            <CityPopup
              actualPage={pageNumber}
              data={response.data}
              totalCount={total_count}
              handlePageChange={handlePageChange}
              loadingPagination={false}
              loadingData={loadingData}
            />
          </Provider>
        );
      } else {
        let currentRoot = rootMap.get(container);
        if (!currentRoot) {
          currentRoot = createRoot(container);
          rootMap.set(container, currentRoot);
        }
        currentRoot.render(<div>No data found</div>);
      }
    };

    fetchAndRender(1);
    return container;
  },
});

const cityDonutPopupTemplate = {
  title: `<div class="flex justify-center items-center">
            <div class="">Recent Mortgages</div>
          </div>`,
  content: [cityDonutCustomContent],
  overwriteActions: true,
};

export const CityDonutLayer = new FeatureLayer({
  id: "CityDonutLayer",
  maxScale: 120828,
  minScale: 1317305,
  portalItem: {
    id: "8f16e0a35a4d48eeb85e5f6488e94e5c",
  },
  visible: true,
  popupTemplate: cityDonutPopupTemplate,
});
